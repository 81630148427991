.label-marker {
  position: absolute;
  left: 0;
  right: 0;
  top: 22px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.label-roundtrip-marker {
  position: absolute;
  right: 13px;
  top: 6px;
  text-align: center;
}

.label-return-marker {
  position: absolute;
  right: 16px;
  top: 15px;
  text-align: center;
}
.SingleBookingLayout {
  div#show-route {
    z-index: 11;
    right: 190px;
    top: 8px !important;
  }
}

.marker-label-drap-drop {
  bottom: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
}

.marker-content-drap-drop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 68px;
  font-size: 15px;
  line-height: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.marker-label-pin {
  font-size: 15px;
  text-align: center;
  display: block;
  margin-top: 22px;
}