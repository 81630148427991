/*
 * Detect small laptop (smaller than 1366px)
 * 80% zoom for 1366 x 768 and 85% zoom for BatchEZ step 3 & 4
 */

 @media (min-width: 1024px) and (max-width: 1366px) {
  .Locating {
    top: 48px;
  }
  .Locating.Locating-status-bar {
    top: 88px;
  }
  .Menu,
  .RightMenu,
  .PageHead,
  .Booking-Multiple-Actions,
  .middle-screen-wrapper .default-wrapper,
  .MultipleBookingLayout + .modal-multipleSavedBookings,
  .MultipleBookingLayout ~ .modal-multipleSavedBookings,
  #saved-bookings-popup,
  #draft-bookings-popup:not(.draft-bookings-popup-text),
  #draft-batches-popup,
  #batch-add-contact-popup,
  .Booking .MultipleBookingLayout .Batch,
  .MultipleBookingLayout .BookingWizard,
  .MultipleBookingLayout .BookingWizard-Actions,
  .MultipleBookingLayout-Collapse-Icon,
  .MultipleBookingLayout-Collapse,
  .BatchUploadLayout section.actions,
  .BatchUploadIntro,
  .BatchUploadUpload .Custom-Step,
  .BatchUploadAssign .Custom-Step,
  .BatchUploadUpload .Content #Step-01,
  .BatchUploadAssign .Content #Step-02,
  .Batch-Custom-Left,
  .Batch-Info-Group.Custom,
  .Batch-Toggle.Batch-Table > table,
  .Batch-Custom .Batch-Custom-Right .Batch-Table-Sortable,
  .zoom-scale-8,
  .Closure .Filter,
  .Closure-Group,
  .DetailBooking-Section,
  .BookingInfo,
  .BookingInfo ~ span > .Modal,
  .BookingInfo + span > .Modal,
  .Modal-Tracking-Chat,
  .Map-Overlay .review_reimbursements,
  .Map-Overlay .Map-Tracking,
  .Wallet .simple_form,
  .Wallet .wallet-screens,
  .Contacts .simple_form,
  .Contacts #contact_list,
  #contact_import_form,
  .Employee .simple_form,
  .Employee table#main-table,
  .MyDrivers #form-search,
  .MyDrivers table#main-table,
  .APIDashboardLayout section,
  .SingleBookingLayout,
  .Block-Shopping,
  #confirmationDialog,
  #popup-available-drivers,
  #closure_modal_popup_content,
  #popup-confirm-cancel,
  .Batch-Toggle.Batch-Chat.Show,
  .Batch-Toggle.Batch-Map.Show,
  .pac-container,
  .Login,
  .Signup,
  .SelectCity.fullscreen,
  #ui-datepicker-div.ui-widget.ui-widget-content,
  .ez-multiple-chat-hotline #form-location-popup,
  .number-driver,
  .change-driver-details,
  .credit-available,
  .rebundle-section,
  .undo-all,
  .summary-pickup .PickupTime-DatePicker,
  .StatusBar,
  .topup-modal,
  .batch-select-header {
    zoom: 0.8;
  }
  .PageHead .StatusBar {
    zoom: 0;
    border-bottom: 0;
  }
  .BatchUploadValidate .Custom-Step,
  .BatchUploadValidate .Content #Step-04,
  .BatchUploadValidate .Content #Step-05,
  .BatchUploadValidate .MultipleBookingLayout-Collapse-Icon,
  .BatchUploadValidate .MultipleBookingLayout-Collapse,
  .BatchUploadValidate .route-detail-popup,
  .BatchUploadPlan .Custom-Step {
    zoom: 0.85;
  }

  .MultipleBookingLayout {
    height: calc(100vh - 208px);
  }
  .Booking .MultipleBookingLayout .MultipleBookingLayout-Area {
    height: calc(100vh - 200px);
  }
  .MultipleBookingLayout .overlay-multiple {
    top: 88px;
    bottom: 45px;
  }
  .Booking-BatchEZ .MultipleBookingLayout-Collapse-Icon {
    top: 95px;
  }
  .Batch-Custom-Left {
    min-height: calc(100vh/0.8 - 130px);
  }
  .Batch-Chat-Right .Custom-Scroll-Group{
    max-height: calc( 100vh/0.8 - 327px );
  }
  .Batch-Map-Right{
    height: calc(100vh/0.8 - 272px)
  }
  .Batch-Custom-Left-Pagination {
    width: 192px;
  }
  .LoginBox .Box-Content .Input{
    overflow: hidden;
    box-sizing: border-box
  }
  .Location-Address-Cell-Modal .pac-container {
    width: 479px !important;
    margin-left: -31px;
    margin-top: 22px;
  }

  #root .Services-List.block {
    max-height: calc(100vh - 150px);
  }
  #root .MultipleBookingLayout .Services-List.block {
    max-height: calc(100vh - 150px - 165px);
  }
  .Shopping-Scroll-Detail {
    width: calc(100vw - 125px);
  }
  .batch-confirm {
    height: calc((100vh - 60px * 0.8) / 0.8);
    zoom: 0.8;
  }
  .change-position-chatbox {
    right: 319px !important;
  }
  .Contacts, .Employee, .MyDrivers{
    padding-top: calc( 60px * 0.8);
  }
  .payment-2c2p {
    width: 100%;
    height: calc(100vh/0.8 - 115px);
    border: 0;
  }
  
  .user-profile .RightMenu-Box .Box-Content {
    max-height: calc(100vh/0.8 - 190px);
  }

  .MultipleBookingLayout {
    height: calc(100vh - 192px);
  }
  .app .booking-detail-root-wrapper {
    padding-top: calc(var(--menu-height) * 0.8)
  }
}