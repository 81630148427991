$xs-small-screen:  320px;
$small-screen:     480px;
$medium-screen:    768px;
$large-screen:     992px;
$xs-large-screen:  1200px;

// Define main color
$colorWhite: #FFFFFF;
$colorGreen: #40b450;
$colorBlue: #2b3e50;
$colorBlueLink: #00adf5;
$colorRedLink: #f44336;
$colorGreenButton: #3fae29;
$colorGrey: #6d6d6d;
$colorbgGrey: #eaeaea;
// $colorbgGrey: #bababa;
$colorBlack: #444444;


$colorGreenLight: #3eb549;
$colorGreenDark: #41A94F;
$colorLightGreen: #80F200;
$colorGray: #cccccc;
$colorYellow: #F7DB1E;
$colorYellowLight: #F5E100;
$colorYellowDark: #FED040;
$colorOrange: #f37021;
$colorGrayLight: #f4f4f4;
$colorGrayDark: #808080;
$colorGrayDarkMore: #707070;
$colorRed: #CE9E9E;
$colorRedLight: #F34133;
$colorRedLighter: #F12515;
$colorBlackSmoke: rgba(0,0,0,0.05);
$colorBlack: #343434;
$colorBlackDark: #000000;
// Font
$fontOpenSans: "Open Sans", sans-serif;
$fontHelveticaNeue: 'Helvetica Neue', Arial, Helvetica, sans-serif;


// Custom mixins
@mixin utility-responsive($width) {
  @media only screen and (min-width: $width) { @content; }
}

@mixin utility-responsive-range($min, $max) {
  @media all and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin custom-background-body($file, $type) {
    background: image-url($file + '.' + $type) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
@mixin custom-rounded($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}